jQuery(document).ready(function ($) {

    // Throttle function to limit execution frequency
    function throttle(func, limit) {
        let inThrottle;
        return function () {
            const args = arguments;
            const context = this;
            if (!inThrottle) {
                func.apply(context, args);
                inThrottle = true;
                setTimeout(() => inThrottle = false, limit);
            }
        };
    }

    // Set height on page load
    setMobileNavHeight();

    // Run setMobileNavHeight() after Slick Slider has been initialized
    $('.js-announcements-slider').on('init', function (event, slick) {
        console.log('announcements slider init');
        setMobileNavHeight();
    });

    // Combined and throttled resize event
    $(window).on('resize', throttle(function () {
        //console.log('resize');
        setMobileNavHeight();

        var activeParentLink = $('.js-parent-link.active');
        if (activeParentLink.length) {
            updateSubNavPosition(activeParentLink);
        }
    }, 200)); // Adjust 200ms as needed

    /*Load top level menu into place if empty*/
    if ($('.js-mobile-nav-links').is(':hidden') || $('.js-mobile-nav-links').is(':empty')) {
        fillDefaultMobileNav();
    }

    $('body').on('click', '.js-mobile-parent-link', function (e) {
        //console.log('clicked');

        if ($(e.target).is('.js-mobile-parent-link')) {
            var dataRef = $(this).data('ref');
            var subNavContents = $('.js-mobile-subnav[data-ref="' + dataRef + '"]').html();
            $('.js-mobile-nav-links').html(subNavContents);
        }
    });

    function updateSubNavPosition(element) {
        //console.log('updateSubNavPosition()');
        var dataRef = element.attr('data-ref');
        var subNav = $('.js-sub-nav[data-ref="' + dataRef + '"]');
        var subNavContent = subNav.html();

        $('.js-nav-dropdown').show();
        $('.js-overlay').show();
        $('.js-subnav-links').html(subNavContent);

        var dataPosition = subNav.attr('data-position');
        if (dataPosition === 'center') {
            var windowWidth = $(window).width();
            var subNavWidth = $('.js-subnav-links').outerWidth();
            var leftPosition = (windowWidth / 2) - (subNavWidth / 2);

            $('.js-subnav-links').css({
                'left': leftPosition + 'px',
                'right': 'auto'
            });
        } else {
            var parentPosition = element.offset();
            var parentWidth = element.outerWidth();
            var subNavWidth = $('.js-subnav-links').outerWidth();
            var leftPosition = parentPosition.left + (parentWidth / 2) - (subNavWidth / 2);

            $('.js-subnav-links').css({
                'left': leftPosition + 'px',
                'right': 'auto'
            });
        }

        var subNavHeight = $('.js-subnav-links').outerHeight();
        $('.js-nav-dropdown').css('height', subNavHeight + 'px');

        if (dataRef == 'search') {
            $('.js-search-input').focus();
        }
    }

    $('.js-parent-link').each(function () {
        var $link = $(this);

        if ($link.data('on') === 'hover') {
            $link.on('mouseover', throttle(function (e) {
                setTimeout(function() {
                    handleDropdown($link, e);
                }, 200);
            }, 200));
        } else {
            $link.on('click', throttle(function (e) {
                handleDropdown($link, e);
            }, 200));
        }
    });

    function handleDropdown(clickedElement, e) {
        //console.log('handleDropdown()');
        setMobileNavHeight();
        e.stopPropagation();

        if (clickedElement.hasClass('active')) {
            hideDropdown();
            if ($('body').hasClass('has-header--transparent')) {
                $('.js-header').addClass('header--transparent');
                $('.js-mobile-header').addClass('mobile-header--transparent');
            }
        } else {
            $('.js-parent-link').removeClass('active');
            clickedElement.addClass('active');
            //console.log('else click');
            updateSubNavPosition(clickedElement);

            if ($('body').hasClass('has-header--transparent')) {
                $('.js-header').removeClass('header--transparent');
                $('.js-mobile-header').removeClass('mobile-header--transparent');
            }
        }
    }

    $(document).on('mouseover', function (e) {
        //console.log('mouseoff');
        if (!$(e.target).closest('.js-nav-dropdown, .js-parent-link, .js-mobile-nav-links, .js-subnav-links, .js-header, .js-mobile-header').length && !$(e.target).is('.js-mobile-parent-link')) {
            hideDropdown();
        }
    });

    function hideDropdown() {
        //console.log('hideDropdown()');
        $('.js-nav-dropdown').hide();
        $('.js-parent-link.active').removeClass('active');
        $('.js-subnav-links').html('');
        fillDefaultMobileNav();
        $('.js-overlay').hide();
    }

    function fillDefaultMobileNav() {
        //console.log('fillDefaultMobileNav()');
        var topNavContents = $('.js-mobile-subnav[data-ref="top"]').html();
        $('.js-mobile-nav-links').html(topNavContents);
    }

    function setMobileNavHeight() {
        //console.log('setMobileNavHeight()');
        var documentHeight = $(document).height();
        var preHeaderHeight = $('.js-pre-header').height();
        var mobileHeaderHeight = $('.js-mobile-header').height();
        var availableHeight = documentHeight - (preHeaderHeight + mobileHeaderHeight);
        $('.js-mobile-navigation').css('min-height', availableHeight + 'px');
    }
});