jQuery(document).ready(function($) {
    $('.js-checkout-apply-coupon').on('click', function(e) {
        e.preventDefault();

        var coupon_code = $('#coupon_giftcard_code').val();
        var nonce = $('input[name="security"]').val(); // Capturing the nonce here

        if (coupon_code.length > 0) {
            // Disable the button and show a loading spinner or message
            $(this).prop('disabled', true).text('Applying...');

            $.ajax({
                type: 'POST',
                url: wc_checkout_params.ajax_url, // WooCommerce AJAX URL
                data: {
                    action: 'apply_coupon_giftcard', // Custom AJAX action
                    coupon_code: coupon_code,
                    security: nonce // Pass the nonce here
                },
                success: function(response) {
                    // Check if the response was successful
                    if (response.success) {
                        // Reload checkout form to display the discount
                        $('.js-checkout-apply-coupon').prop('disabled', false).text('Apply');
                        $('body').trigger('update_checkout');
                    } else {
                        // Re-enable the button and display an error message
                        $('.js-checkout-apply-coupon').prop('disabled', false).text('Apply');
                        dialogify(response.data);
                    }
                },
                error: function() {
                    // Re-enable the button on error
                    $('.js-checkout-apply-coupon').prop('disabled', false).text('Apply');
                    dialogify('Failed to apply coupon or gift card.');
                }
            });
        } else {
            dialogify('Please enter a coupon or gift card code.');
        }
    });

    $('.js-show-coupon-form').on('click', function() {
        // Show the coupon form with display: flex
        $('.js-coupon-form').css('display', 'flex');

        // Hide the button that triggered the form to appear
        $(this).hide();
    });

    $('.js-coupon-form').on('submit', function(e) {
        e.preventDefault();
        $('.js-checkout-apply-coupon').trigger('click');
    });

    $(document).on('click', '.js-checkout-remove-coupon', function(e) {
        e.preventDefault();

        var coupon_code = $(this)
            .parent()
            .clone()
            .children()
            .remove()
            .end()
            .text();

        var nonce = $('input[name="remove_coupon_nonce"]').val();

        console.log(coupon_code);

        if (coupon_code.length > 0) {
            $.ajax({
                type: 'POST',
                url: wc_checkout_params.ajax_url, // WooCommerce AJAX URL
                data: {
                    action: 'remove_coupon_giftcard', // Custom AJAX action
                    coupon_code: coupon_code,
                    security: nonce // Pass the nonce here
                },
                success: function(response) {
                    // Check if the response was successful
                    if (response.success) {
                        // Reload checkout form to display the discount
                        $('body').trigger('update_checkout');
                    } else {
                        // display an error message
                        dialogify(response.data);
                    }
                },
                error: function() {
                    // Re-enable the button on error
                    dialogify('Failed to remove coupon or gift card.');
                }
            });
        }
    });
});
